<template>
	<div class=" ">
		<el-card>
			<div slot="header" class="card_title">
				<span>Bot</span>
			</div>
			<div class="add_main hidden_scroll">
				<p class="font_weight_bold" style="height: 20px"></p>
				<p class="font_weight_bold" style="font-size: 20px; color: red"
					>1、请注意，添加的bot信息，必须为我们已有文章的关联信息</p
				>
				<p class="font_weight_bold" style="font-size: 20px; color: red"
					>2、请注意，封面图片不想用上传，默认使用的是文章的封面图</p
				>
				<p class="font_weight_bold" style="height: 20px"></p>

				<div class="margin_top_20">
					<el-form
						label-position="right"
						label-width="120px"
						:model="formData"
						:rules="rule"
						ref="formData"
					>
						<el-form-item label="ID" class="margin_top_20" prop="contentId">
							<el-input
								v-model="formData.contentId"
								placeholder="文章的id"
								:disabled="$route.query.type == 'detail'"
								style="width: 200px"
							>
							</el-input>

							<button style="margin-left: 20px" @click="getInformationDetail()"
								>查询</button
							>
						</el-form-item>
						<el-form-item label="主标题" class="margin_top_20" prop="title">
							<el-input
								v-model="formData.title"
								placeholder="默认为选择的文章的标题"
								:disabled="$route.query.type == 'detail'"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="内容展示" class="margin_top_20" prop="desc">
							<el-input
								v-model="formData.desc"
								placeholder="推送bot显示的正文内容"
								:disabled="$route.query.type == 'detail'"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="主内容链接" class="margin_top_20" prop="url">
							<el-input v-model="formData.url" placeholder=""> </el-input>
						</el-form-item>
						<el-form-item label="封面图片" prop="pic">
							<img style="width: 40%" :src="formData.pic" />
						</el-form-item>
						<p
							class="font_weight_bold"
							style="font-size: 20px; color: red; margin-bottom: 20px"
							>以下内容可不填，填写的话，建议整组方式填写</p
						>
						<el-form-item label="其他推荐内容分组一" prop="expandTitle1">
							<el-input
								v-model="formData.expandTitle1"
								placeholder="请填入分组标题（如：ME主编也推荐你读）"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="推荐内容一">
							<el-input
								v-model="formData.expandSubName1_1"
								placeholder="推荐内容一标题"
							>
							</el-input>
							<el-input
								v-model="formData.expandSubUrl1_1"
								placeholder="推荐内容一链接"
								style="margin-top: 20px"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="推荐内容二">
							<el-input
								v-model="formData.expandSubName1_2"
								placeholder="推荐内容二标题"
							>
							</el-input>
							<el-input
								v-model="formData.expandSubUrl1_2"
								placeholder="推荐内容二链接"
								style="margin-top: 20px"
							>
							</el-input>
						</el-form-item>

						<el-form-item label="其他推荐内容分组二">
							<el-input
								v-model="formData.expandTitle2"
								placeholder="请填入分组标题（如：ME主编也推荐你读）"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="推荐内容一">
							<el-input
								v-model="formData.expandSubName2_1"
								placeholder="推荐内容一标题"
							>
							</el-input>
							<el-input
								v-model="formData.expandSubUrl2_1"
								placeholder="推荐内容一链接"
								style="margin-top: 20px"
							>
							</el-input>
						</el-form-item>
						<el-form-item label="推荐内容二">
							<el-input
								v-model="formData.expandSubName2_2"
								placeholder="推荐内容二标题"
							>
							</el-input>
							<el-input
								v-model="formData.expandSubUrl2_2"
								placeholder="推荐内容二链接"
								style="margin-top: 20px"
							>
							</el-input>
						</el-form-item>
						<p
							class="font_weight_bold"
							style="font-size: 20px; color: red; margin-bottom: 20px"
							>发布时间不选择和选择小于当前时间即默认为立即发布
						</p>
						<el-form-item label="选择发布时间" prop="release_time">
							<el-date-picker
								v-model="formData.release_time"
								:disabled="$route.query.type == 'detail'"
								value-format="yyyy-MM-dd HH:mm:ss"
								format="yyyy-MM-dd HH:mm:ss"
								type="datetime"
								placeholder=""
							>
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									class="custom_btn_width_100"
									@click="$router.push({ path: '/information/telegram' })"
									>返回</el-button
								>
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="save(0)"
									style="background: gray"
								>
									仅保存
								</el-button>
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="save(1)"
									>推送Bot</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
		<div v-if="loading" class="loading-overlay">
			<!-- loading遮罩内容 -->
			<div class="spinner"></div>
		</div>
	</div>
</template>

<script>
import { validateNull } from "@/utils/validate";
import { updateBotDetai, botDetail, getInformationDetail } from "@/api/article";
export default {
	components: {},
	name: "",
	data() {
		return {
			rule: {
				title: [{ validator: validateNull, trigger: "blur", required: true }],
				desc: [{ validator: validateNull, trigger: "blur", required: true }],
				pic: [{ validator: validateNull, trigger: "blur", required: true }],
				contentId: [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				url: [{ validator: validateNull, trigger: "blur", required: true }],
				type: [{ validator: validateNull, trigger: "change", required: true }],
			},
			loading: false,
			formData: {
				title: "",
				contentId: "",
				type: "0",
				id: "",
				pic: "",
				desc: "",
				url: "",
				status: "0",
				bot: 0,
				items: [],
				release_time: "",
				expandTitle1: "",
				expandSubName1_1: "",
				expandSubUrl1_1: "",
				expandSubName1_2: "",
				expandSubUrl1_2: "",
				expandTitle2: "",
				expandSubName2_1: "",
				expandSubUrl2_1: "",
				expandSubName2_2: "",
				expandSubUrl2_2: "",
			},
		};
	},
	created() {
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.details();
		}
	},

	mounted() {},
	methods: {
		// 获取资讯详情
		getInformationDetail() {
			const that = this;
			getInformationDetail({ id: that.formData.contentId })
				.then((res) => {
					if (res.code == 200) {
						that.formData.pic = res.data.pic;
						that.formData.desc = res.data.illustrate;
						that.formData.title = res.data.title;
						that.formData.url =
							"https://web.metaera.hk/article/" + that.formData.contentId;
					} else {
						that.$message.error("查询失败，请确认ID");
					}
				})
				.catch((error) => {
					that.$message.error("查询失败，请确认ID");
				});
		},
		details() {
			const that = this;
			botDetail({ id: that.$route.query.id })
				.then((res) => {
					console.log(res);

					if (res.code == 200) {
						let router = this.$route.query.type;
						if (router == undefined || router.length == 0) {
						} else if (router == "copy") {
						} else if (router == "detail") {
							if (
								res.data.release_time != undefined &&
								res.data.release_time != null
							) {
								that.formData.release_time = res.data.release_time.toString();
							}
						} else if (router == "edit") {
							if (
								res.data.release_time != undefined &&
								res.data.release_time != null
							) {
								that.formData.release_time = res.data.release_time.toString();
							}
						} else {
						}

						that.formData.type = res.data.type.toString();
						that.formData.title = res.data.title.toString();
						that.formData.desc = res.data.desc.toString();
						that.formData.contentId = res.data.contentId.toString();
						that.formData.id = res.data.id.toString();
						that.formData.pic = res.data.pic.toString();
						that.formData.url = res.data.url.toString();
						that.formData.pic = res.data.pic.toString();
						that.formData.status = res.data.status.toString();
						that.formData.items = JSON.parse(res.data.items);
						if (that.formData.items != undefined) {
							that.formData.expandTitle1 = that.formData.items[0].title;

							that.formData.expandSubName1_1 =
								that.formData.items[0].list[0].name;
							that.formData.expandSubUrl1_1 =
								that.formData.items[0].list[0].url;

							that.formData.expandSubName1_2 =
								that.formData.items[0].list[1].name;
							that.formData.expandSubUrl1_2 =
								that.formData.items[0].list[1].url;

							that.formData.expandTitle2 = that.formData.items[1].title;

							that.formData.expandSubName2_1 =
								that.formData.items[1].list[0].name;
							that.formData.expandSubUrl2_1 =
								that.formData.items[1].list[0].url;

							that.formData.expandSubName2_2 =
								that.formData.items[1].list[1].name;
							that.formData.expandSubUrl2_2 =
								that.formData.items[1].list[1].url;
						}
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					console.log(error);
					that.$message.error(error.message);
				});
		},
		save(bot) {
			let router = this.$route.query.type;
			if (router == undefined || router.length == 0) {
				this.formData.id = "";
			} else if (router == "copy") {
				this.formData.id = "";
			} else if (router == "detail") {
			} else if (router == "edit") {
			} else {
			}

			if (
				this.formData.contentId == undefined ||
				this.formData.contentId.length == 0 ||
				this.formData.title == undefined ||
				this.formData.title.length == 0 ||
				this.formData.url == undefined ||
				this.formData.url.length == 0 ||
				this.formData.desc == undefined ||
				this.formData.desc.length == 0
			) {
				this.$message.error("请填写主要信息");
				return;
			}
			this.formData.status = 0;

			this.formData.items = [
				{
					title:
						this.formData.expandTitle1 == undefined
							? ""
							: this.formData.expandTitle1,
					list: [
						{
							name: this.formData.expandSubName1_1,
							url: this.formData.expandSubUrl1_1,
						},
						{
							name: this.formData.expandSubName1_2,
							url: this.formData.expandSubUrl1_2,
						},
					],
				},
				{
					title: this.formData.expandTitle2,
					list: [
						{
							name: this.formData.expandSubName2_1,
							url: this.formData.expandSubUrl2_1,
						},
						{
							name: this.formData.expandSubName2_2,
							url: this.formData.expandSubUrl2_2,
						},
					],
				},
			];

			this.formData.bot = bot;
			this.formData.status = bot;
			this.loading = true;
			updateBotDetai(this.formData)
				.then((res) => {
					this.loading = false;
					if (res.code == 200) {
						this.$router.push({ path: "/information/telegram" });
					} else {
					}
				})
				.catch((error) => {
					this.loading = false;
				});
		},
		clearFormAdd() {
			for (let key in this.formData) {
				this.formData[key] = "";
			}
			this.formData.type = "0";
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	height: 81vh;
	overflow-y: scroll;
	margin: 0 auto;
}
</style>
<style>
.distpicker-address-wrapper select {
	width: 255px !important;
	padding: 0px 10px !important;
	height: 35px !important;
	font-size: 18px !important;
	line-height: 35px !important;
}
</style>
<!-- ac_create_ticket -->
<style scoped>
.ac_create_ticket {
	margin-top: 10px;
}
.ac_create_ticket_header {
	height: 60px;
	border-radius: 10px;
	background: rgba(240, 240, 240, 0.5);
	display: flex;
	align-items: center;
}

.ac_create_ticket_header_1,
.ac_create_ticket_header_2,
.ac_create_ticket_header_3,
.ac_create_ticket_header_4,
.ac_create_ticket_header_5 {
	width: 20%;
	text-align: center;
	align-content: center;
}

.ac_create_ticket_add {
	display: flex;
	margin-top: 20px;
	align-items: center;
}

.ac_create_ticket_add span {
	font-family: PingFang SC;
	font-size: 16px;
	font-weight: normal;
	line-height: 22px;
	display: flex;
	align-items: center;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	/* 333 */
	color: #333333;
}

.ac_create_ticket_add_name {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_type {
	text-align: center;
	align-content: center;
	width: 15%;
	padding-left: 1%;
	padding-right: 1%;
	margin-left: 2%;
	margin-right: 2%;
	height: 50px;
	border-radius: 10px;
	opacity: 1;
	border: 1px solid #333333;
}

.ac_create_ticket_add_type_down {
	width: 100%;
	height: 50px;
	border-radius: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ac_create_ticket_add_type_down span {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.ac_create_ticket_down_menu {
	width: 160px;
}

.ac_create_ticket_down_menu_item {
	height: 40px;
	/* text-align: center; */
	align-content: center;
	align-items: center;
}

.ac_create_ticket_add_code {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_price {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}
.ac_create_ticket_add_num {
	text-align: center;
	align-content: center;
	width: 16%;
	margin-left: 2%;
	margin-right: 2%;
}

.ac_create_ticket_add_num_del {
	width: 34px;
	height: 34px;
	opacity: 1;
}
.ac_create_ticket_add_num_del img {
	width: 100%;
	height: 100%;
}
</style>
<style scoped>
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	/* 样式可以根据需求自行调整 */
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	border-top: 4px solid #fff;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
}

.card_title {
	text-align: center;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
